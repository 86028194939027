.landing-alert{
    margin-bottom: 0;
}
.page-header{
    background-color: #B2AFAB;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .filter::after{
        background-color: rgba(0, 0, 0, 0.2);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }

    &.section-dark{
        .content-center,
        .category-absolute{
            z-index: 1;
            width: 100%;
        }
    }
}
.page-header .motto{
    text-align: left;
    z-index: 3;
    color: #fff;
    position: relative;

}

// HOME
.landing-section{
    padding: 100px 0;
    z-index: 1;
}
.landing-intro{
    z-index: 1;
    color: $black-color;
    background: url(../../img/roentgen/home/water_bg.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .intro{
        background-color: rgba(255, 255, 255, 0.9);
    }
}
.landing-latest-news{
    background-color: $white-states-color !important;
    padding: 30px 0 !important;
}
.landing-technology-partners{
    z-index: 1;
    color: $black-color;
    background-color: #FFF9EC !important;
    background-image: url(../../img/roentgen/home/carousel/polkadots.png), url(../../img/roentgen/home/carousel/polkadots.png);
    background-position: 96% 90%, 4% 10%;
    background-repeat: no-repeat, no-repeat;
    background-size: 150px;
}
.landing-trusted-companies {
    z-index: 1;
    color: $black-color;
    padding-bottom: 100px !important;
    background-image: url(../../img/roentgen/home/water_bg_2.png);
    background-repeat: no-repeat;
    background-position: center;
}
.landing-section .btn-simple{
    padding: 0;
}
.landing-section .column{
    padding: 0 75px 0 25px;
}

// ABOUT US
.about-us-intro {
    z-index: 1;
    height: 300px;
    background-image: url(../../img/roentgen/about_us/about_us_bg.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.about-us-description{
    color: $black-color;
    .nav{
        background-color: $font-light-yellow-color;
        padding: 15px 0;
    }
    .nav-tabs, .nav-link{
        border: none;
        color: $white-color;
        font-weight: normal;
        .active{
            color: $white-color;
            font-weight: bold;
            background-color: $font-light-yellow-color;
        } 
    }
    .nav-item{
        flex: 1;
        border: none;
        text-align: center;
        cursor: pointer;
        color: $white-color;
    }

    .content{
        p{
            font-size: 17px !important;
        }
        @media only screen and (max-width: 992px){
            padding: 10px;
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    .about-us-history{
        padding: 120px 0;
        padding-left: 300px;
        padding-right: 150px;
        background-image: url(../../img/roentgen/about_us/about_us_bg1.png);
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: contain;
        p{
            text-align: justify;
            text-justify: inter-word;
        }
        @media only screen and (max-width: 992px){
            padding: 50px 0;
            background-size: cover;
        }
    }
    
    .about-us-leadership{
        background-image: url(../../img/roentgen/about_us/leadership_bg.png);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        p{
            text-align: justify;
            text-justify: inter-word;
        }
        @media only screen and (max-width: 767px){
            .leadership-img {
                width: 300px;
            }
        }
    }
    
    .about-us-company-profile{
        background-image: url(../../img/roentgen/about_us/leadership_bg.png);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        .company-profile-img {
            width: 750px;
        }
        @media only screen and (max-width: 767px){
            .company-profile-img {
                width: 300px;
            }
        }
    }
}

// PRODUCTS AND SERVICES
.products-services-intro {
    z-index: 1;
    height: 300px;
    background-image: url(../../img/roentgen/products_and_services/products_and_services_bg.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.products-and-services-details{
    color: $black-color;
    .nav{
        background-color: $font-light-yellow-color;
        padding: 15px 0;
    }
    .nav-tabs, .nav-link{
        border: none;
        color: $white-color;
        font-weight: normal;
        .active{
            color: $white-color;
            font-weight: bold;
            background-color: $font-light-yellow-color;
        } 
    }
    .nav-item{
        flex: 1;
        border: none;
        text-align: center;
        cursor: pointer;
        color: $white-color;
    }

    .products-and-services-equipments,
    .products-and-services-chemicals{
        padding: 60px 0;
        background-image: url(../../img/roentgen/about_us/about_us_bg1.png);
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: contain;
        @media only screen and (max-width: 767px){
            background-size: cover;
            padding: 50px 0;

            .container-fluid{
                padding: 0 20px !important;
            }
        }

        .equipments-vertical-nav{
            background-color: $white-color;
            text-align: left !important;
            border-radius: 10px;
            .nav-link{
                color: $black-color;
                padding: 17px 0;
                text-align: left;
                padding-left: 20px;
                &:hover{
                    background-color: rgba(16, 177, 245, 0.08);
                }
            }
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                padding-bottom: 0;
            }
            .active{
                background-color: rgba(16, 177, 245, 0.08);
                border-left: 8px solid #14B4FA;
            }
        }
        .card{
            .card-img-overlay{
                opacity: 0;
                transition: transform .18s ease-in;
                border-radius: 12px;
            }
            .card-text{
                opacity:0;
                color: $white-color;
                transition: opacity .05s ease-in, transform .25s ease-in;
            }
            &:hover{
                .card-img{
                    opacity: 0.8;
                }
                .card-img-overlay{
                    opacity:1;
                    transition: transform .25s ease-out;
                    background-color: rgba(20, 180, 250, 0.9);
                }
                .card-text{
                    opacity: 1;
                    transform: translateX(0);
                }
                .default-card-text{
                    opacity:0.8;
                }
            }
        }
    }
    .products-and-services-services{
        background-image: url(../../img/roentgen/products_and_services/water_splash.png);
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
    }

    .inquire-button {
        right: 0;
        bottom: 0;
        position: fixed;
        padding: 5px 10px;
        margin: 50px;
        z-index: 10;
        background-image: linear-gradient(to right,  rgb(238, 255, 0), rgb(255, 81, 0));
        border: none;
        border-radius: 0;
        cursor: pointer;
        color: black;
        font-size: 16px;
    }
}

// SUSTAINABILITY
.sustainability {
    color: $black-color;
    .sustainability-img-column {
        color: $white-color;
        height: 80vh;
        .carousel {
            margin: 0 !important;
        }
        .carousel-inner .carousel-item {
            // background-image: url(../../img/roentgen/sustainability/sustainability_bg.png);
            height: 80vh;
            img{
                height: 100%;
                border-radius: 0;
                object-fit: cover !important; 
            }
            // @media only screen and (max-width: 1200px){
            //     height: 100vh;
            // }
        }
        .carousel-control-prev,
        .carousel-control-next {
            display: none;
        }
    }
    .sustainability-details-column{
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 80px;
        background-image: url(../../img/roentgen/sustainability/sustainabilty_splash.png);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        p{
            font-weight: normal;
        }
        @media only screen and (max-width: 1200px){
            padding: 80px 40px;
            height: 80vh;
        }
    }
}

// CAREERS
.careers {
    color: $black-color;
    p{
        text-align: justify;
        text-justify: inter-word;
    }
    .main-img{
        width: 100%;
    }
    .section-one{
        padding: 40px 0;
        .title{
            margin-top: 160px;
            font-size: 3.5rem;
        }
        .polka-img{
            position: absolute;
            right: 0;
            bottom: 0;
        }
        @media only screen and (max-width: 990px){
            .title{
                margin-top: 0;
                font-size: 2.5rem;
                text-align: center;
            }
            .large-screen-row{
                display: none !important;
            }
            .small-screen-row{
                display: block !important;
            }
            .polka-img{
                width: 150px;
            }
            .main-img{
                margin-top: -30px !important;
            }
        }
    }
    .section-two{
        padding: 40px 0;
        @media only screen and (max-width: 990px){
            .large-screen-row{
                display: none !important;
            }
            .small-screen-row{
                display: block !important;
                .small-screen-img-column{
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .section-three{
        padding: 40px 0;
        @media only screen and (max-width: 990px){
            .main-img{
                margin-bottom: 2rem;
            }
        }
    }
    .section-four{
        padding: 40px 0;
        @media only screen and (max-width: 990px){
            .large-screen-row{
                display: none !important;
            }
            .small-screen-row{
                display: block !important;
            }
        }
    }
    .section-five{
        padding: 40px 0;
        @media only screen and (max-width: 990px){
            .main-img{
                margin-bottom: 2rem;
            }
        }
    }
    .join-our-team{
        padding: 40px 0;
    }
    .jobs{
        .job-item{
            color: $white-color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 0 60px;
        }
    }
}

// CLIENTS
.clients-intro {
    z-index: 1;
    height: 300px;
    background-image: url(../../img/roentgen/clients/clients_bg.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.clients-details{
    color: $black-color;
    .nav{
        background-color: $font-light-yellow-color;
        padding: 15px 0;
    }
    .nav-tabs, .nav-link{
        border: none;
        color: $white-color;
        font-weight: normal;
        .active{
            color: $white-color;
            font-weight: bold;
            background-color: $font-light-yellow-color;
        } 
    }
    .nav-item{
        flex: 1;
        border: none;
        text-align: center;
        cursor: pointer;
        color: $white-color;
    }
}
.clients-tech-partners,
.clients-clients{
    z-index: 1;
    color: $black-color;
    background-image: url(../../img/roentgen/clients/tech_partners_1.png), url(../../img/roentgen/clients/tech_partners_2.png);
    background-position: 0 0, 100% 100%;
    background-repeat: no-repeat, no-repeat;
    background-size: 60%, 20%;
}

// CONTACT US
.contact-us {
    color: $black-color;
    .contact-img-column {
        color: $white-color;
        padding: 30px 150px;
        background-image: url(../../img/roentgen/contact/contact_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
        h1{
            font-size: 7em;
            margin-bottom: 15px;
        }
        p{
            font-size: 28px;
        }
        @media only screen and (max-width: 1200px){
            height: 50vh;
            padding: 30px 30px;
            h1{
                font-size: 4em;
            }
            p{
                font-size: 24px;
            }
        }
    }
    .contact-details-column{
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 80px;
        background-image: url(../../img/roentgen/contact/contact_bg1.png);
        background-position: 92% 95%;
        background-repeat: no-repeat;
        background-size: 200px;
        p{
            font-weight: normal;
        }
        @media only screen and (max-width: 1200px){
            padding: 40px 30px;
        }
        .social [class*="fa fa-"] {
            border-radius: 30px;
            color: $white-color;
            display: inline-block;
            height: 30px;
            line-height: 30px;
            margin-right: 16px;
            width: 30px;
            font-size: 15px;
            text-align: center;
            cursor: pointer;
        }
        .fa-linkedin{
            background: #0072b1;
        }
        .fa-facebook{
            background: #4267B2;
        }
        .fa-twitter{
            background: #053eff;
        }
        .fa-linkedin:hover {
            color: #0072b1;
            background-color: $medium-gray; 
            transition: background-color 0.2s ease;
        }
        .fa-facebook:hover { 
            color: #4267B2;
            background-color: $medium-gray;
            transition: background-color 0.2s ease;
        }
        .fa-twitter:hover { 
            color: #053eff;
            background-color: $medium-gray;
            transition: background-color 0.2s ease;
        }
    }
}

.email-address:hover{
    text-decoration: underline;
}

.team-player .img-circle, .team-player .img-thumbnail{
    display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}
.contact-form{
    margin-top: 30px;
}
.contact-form label{
    margin-top: 15px;
}
.contact-form .btn{
    margin-top: 30px;
}

.cursor-pointer{
    cursor: pointer;
}
.navbar-relative{
    position: relative;
    z-index: 2;
}
#register-navbar a{
    color: #FFF;
}
.register-background .container{
    margin-top: 11%;
    position: relative;
    z-index: 3;
}
.register-footer{
    bottom: 20px;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: transparent;
    color: $white-color;
}
.register-footer .fa-heart{
    color: #EB5E28;
}

.register-card label{
    margin-top: 15px;
}
.register-card .title{
    color: #B33C12;
    text-align: center;
}
.register-card .btn{
    margin-top: 30px;
}
.register-card .forgot{
    text-align: center;
}

.profile-content{
    position: relative;
}
.owner{
    text-align: center;
}
.owner .avatar{
    padding: 15px;
    max-width: 180px;
    margin: -85px auto 0;
    display: inline-block;
}
.owner .name h4{
    margin-top: 10px;
}
.profile-tabs{
    margin: 50px 0;
    min-height: 300px;
}
#following h3{
    margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox{
    margin-top: -15px;
}
#follows .follows h6{
    margin-top: 15px;
}
.follows hr{
    margin-top: 10px;
}
.alert h5{
    // margin-bottom: 10px;
}
.title-brand{
    margin: 0 auto;
    position: relative;
    text-align: left;
    color: #FFFFFF;
    display: block;

    .type{
        position: absolute;
        font-size: 20px;
        background: #132026;
        padding: 6px 10px;
        border-radius: 4px;
        top: 0;
        font-weight: 600;
        margin-top: 10px;
        right: -15px;
    }
}

.presentation-title{
    font-size: 5em;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    background: #fbf8ec;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.presentation-subtitle{
    font-size: 1.7em;
    color: #FFFFFF;
}

.category-absolute{
    position: absolute;
    text-align: center;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
}

.page-header{
    .content-center{
        margin-top: 85px;
    }
}
.moving-clouds{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    -webkit-animation: cloudLoop 80s linear infinite;
    animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop{   0%{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
                        100%{-webkit-transform:translate3d(-50%,0,0);
                        transform:translate3d(-50%,0,0)}
                    }
.following{
    img{
        max-width: 70px;
    }
}


.profile-content{
    & .tab-content{
        & .tab-pane{
            min-height: 200px;
        }
        & #tweets, #connections, #media{
            height: 100%;
        }
    }
}
